// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

$( document ).ready(function() { 
	
	$('.logo-slider').slick({
		  slidesToShow: 4,
		  slidesToScroll: 1,
		  arrows : false,
		  infinite: true,
		  autoplay: true,
		  autoplaySpeed: 2000,
		  responsive: [
			  {
				breakpoint: 1024,
				settings: {
				  slidesToShow: 3,
				  slidesToScroll: 3,
				}
			  },
			  {
				breakpoint: 600,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 2
				}
			  }
			  // You can unslick at a given breakpoint now by adding:
			  // settings: "unslick"
			  // instead of a settings object
			]
		});
		
		$('.logo-slider2').slick({
		  slidesToShow: 4,
		  slidesToScroll: 1,
		  arrows : false,
		  infinite: true,
		  autoplay: true,
		  autoplaySpeed: 2000,
		  responsive: [
			  {
				breakpoint: 1024,
				settings: {
				  slidesToShow: 3,
				  slidesToScroll: 3,
				}
			  },
			  {
				breakpoint: 600,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 2
				}
			  }
			  // You can unslick at a given breakpoint now by adding:
			  // settings: "unslick"
			  // instead of a settings object
			]
		});
	
	if ($(window).width() < 1200) {
	   $(window).scroll(function() {   
		   var scroll = $(window).scrollTop();
		   if (scroll >= 1) {
			   $("#navbar-brand-container").addClass("scrollHeader");
		   } else {
			   $("#navbar-brand-container").removeClass("scrollHeader");
		   }
	   });
	}
	else {
	   $(window).scroll(function() {    
		   var scroll = $(window).scrollTop();
		   if (scroll >= 50) {
			   $("#navbar-brand-container").addClass("scrollHeader");
		   } else {
			   $("#navbar-brand-container").removeClass("scrollHeader");
		   }
	   });
	}
	
	$( ".navbar-toggler" ).on( "click", function() {
		$(this).find('#nav-opener').toggleClass('d-none'); 
		$(this).find('#nav-closer').toggleClass('d-none').toggleClass('d-block'); 
	});
}); 