// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

$( document ).ready(function() {
	setTimeout(function() { // Timeout, um sicherzustellen, dass das Event verarbeitet wird
			$('#wsf-1-field-130').find("option:eq(1)").attr('selected' , 'selected');
			$(".delete-post").click(function () {
				var postId = $(this).data("id");
		
				if (confirm("Willst du diesen Spieler wirklich löschen?")) {
					$.ajax({
						type: "POST",
						url: myAjax.ajaxurl, // AJAX-URL von WordPress
						data: {
							action: "delete_custom_post",
							post_id: postId,
							security: myAjax.nonce,
						},
						success: function (response) {
							if (response.success) {
								//$(".delete-message").text("Post erfolgreich gelöscht.");
								location.reload(); // Seite neu laden
							} else {
								//alert("Fehler: " + response.data.message);
							}
						},
						error: function () {
							//alert("Es gab ein Problem beim Löschen des Beitrags.");
						},
					});
				}
			});
			
			
			$(".edit-post").click(function () {
				var postId = $(this).data("id");
				var teamId = $(this).data("teamid");
				
				
				
				//let teamselect = $(this).find("option:eq(1)").attr("selected" , "selected");
				$('#wsf-1-field-130').find("option:eq(1)").attr("selected" , "selected");
				let spielerSelect = $('#wsf-1-field-127');
			
					$.ajax({
						type: "POST",
						url: myAjax.ajaxurl, // AJAX-URL von WordPress
						data: {
							action: "edit_team_players",
							post_id: postId,
							team_id: teamId,
							security: myAjax.nonce,
						},
						success: function (response) {
							//console.log('AJAX Antwort:', response); // Debugging
							
							if (response.success) {
								//spielerSelect.append('<option value="">Spieler wählen</option>');
								spielerSelect.empty();
								$.each(response.data, function(index, spieler) {
									spielerSelect.append('<option value="' + spieler.id + '">' + spieler.name + '</option>');
								});
								$('#wsf-1-field-130').find("option:eq(1)").attr('value' , teamId);
								$('#wsf-1-field-130').find("option:eq(1)").attr('selected' , 'selected');
								
								$.ajax({
									type: 'POST',
									url: ajaxurl,
									data: {
										action: 'load_team_players_fields',
										player_id: postId
									},
									success: function(response) {
										//console.log('AJAX Antwort:', response); // Debugging
								
										if (response.success) {
											//console.log(response.data);
											$('#wsf-1-field-132-dropzonejs img src').val(response.data.thumbnail);
											$('#wsf-1-field-117').val(response.data.name);
											//$('#wsf-1-field-118').val(response.data.vorname);
											$('#wsf-1-field-119').val(response.data.geburtsjahr);
											$('#wsf-1-field-120').val(response.data.ruckennummer);
											$('#wsf-1-field-126 option[value='+response.data.welche_position_spielst_du+']').attr("selected", "selected");
											$('#wsf-1-field-121').val(response.data.wer_ist_dein_lieblingsfusballer);
											$('#wsf-1-field-122').val(response.data.was_ist_dein_lieblingsverein_in_europa);
											$('#wsf-1-field-123').val(response.data.was_ist_dein_lieblingsessen);
											$('#wsf-1-field-124').val(response.data.was_ist_dein_lieblingsfach_in_der_schule);
											$('#wsf-1-field-125').val(response.data.welchen_traumberuf_hast_du);
										} else {
											//alert('Fehler: ' + response.data.message);
										}
									},
									error: function(error) {
										//console.log('AJAX Fehler:', error); // Debugging
									}
								});
								
							} else {
								//alert('Fehler: ' + response.data.message);
							}
						},
						error: function () {
							//alert("Es gab ein Problem beim Löschen des Beitrags.");
						},
					});
				
			});


		
		
		
		$('#wsf-1-field-130').on('change', function() {
			let teamId = $(this).find("option:eq(1)").attr("data-id"); 
			let spielerSelect = $('#wsf-1-field-127');
	
			console.log('Mannschaft geändert:', teamId); // Debugging
	
			// Select-Feld leeren
			spielerSelect.empty();
	
			if (teamId) {
				console.log('Sende AJAX-Request:', teamId); // Debugging
	
				$.ajax({
					type: 'POST',
					url: ajaxurl,
					data: {
						action: 'load_team_players',
						team_id: teamId
					},
					success: function(response) {
						//console.log('AJAX Antwort:', response); // Debugging
	
						if (response.success) {
							spielerSelect.append('<option value="">Spieler wählen</option>');
							
							$.each(response.data, function(index, spieler) {
								spielerSelect.append('<option value="' + spieler.id + '">' + spieler.name + '</option>');
							});
							$('#wsf-1-field-130').find("option:eq(1)").attr('value' , teamId);
						} else {
							//alert('Fehler: ' + response.data.message);
						}
					},
					error: function(error) {
						//console.log('AJAX Fehler:', error); // Debugging
					}
				});
			}
		});
	}, 100); // 300ms Timeout, um sicherzustellen, dass das Event korrekt erfasst wird
	setTimeout(function() {
	$('#wsf-1-field-127').on('change', function() {
			var playerID = $(this).val();
			
	
			//console.log('Spieler geändert:', playerID); // Debugging
	
			if (playerID) {
				//console.log('Spieler AJAX-Request:', playerID); // Debugging
	
				$.ajax({
					type: 'POST',
					url: ajaxurl,
					data: {
						action: 'load_team_players_fields',
						player_id: playerID
					},
					success: function(response) {
						//console.log('AJAX Antwort:', response); // Debugging
	
						if (response.success) {
							//console.log(response.data);
							$('#wsf-1-field-132-dropzonejs img src').val(response.data.thumbnail);
							$('#wsf-1-field-117').val(response.data.name);
							//$('#wsf-1-field-118').val(response.data.vorname);
							$('#wsf-1-field-119').val(response.data.geburtsjahr);
							$('#wsf-1-field-120').val(response.data.ruckennummer);
							$('#wsf-1-field-126 option[value='+response.data.welche_position_spielst_du+']').attr("selected", "selected");
							$('#wsf-1-field-121').val(response.data.wer_ist_dein_lieblingsfusballer);
							$('#wsf-1-field-122').val(response.data.was_ist_dein_lieblingsverein_in_europa);
							$('#wsf-1-field-123').val(response.data.was_ist_dein_lieblingsessen);
							$('#wsf-1-field-124').val(response.data.was_ist_dein_lieblingsfach_in_der_schule);
							$('#wsf-1-field-125').val(response.data.welchen_traumberuf_hast_du);	
						} else {
							//alert('Fehler: ' + response.data.message);
						}
					},
					error: function(error) {
						//console.log('AJAX Fehler:', error); // Debugging
					}
				});
			}
		});
	}, 100); // 300ms Timeout, um sicherzustellen, dass das Event korrekt erfasst wird
	
	
	$('.logo-slider').slick({
		  slidesToShow: 4,
		  slidesToScroll: 1,
		  arrows : false,
		  infinite: true,
		  autoplay: true,
		  autoplaySpeed: 2000,
		  responsive: [
			  {
				breakpoint: 1024,
				settings: {
				  slidesToShow: 3,
				  slidesToScroll: 3,
				}
			  },
			  {
				breakpoint: 600,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 2
				}
			  }
			  // You can unslick at a given breakpoint now by adding:
			  // settings: "unslick"
			  // instead of a settings object
			]
		});
		
		$('.logo-slider2').slick({
		  slidesToShow: 4,
		  slidesToScroll: 1,
		  arrows : false,
		  infinite: true,
		  autoplay: true,
		  autoplaySpeed: 2000,
		  responsive: [
			  {
				breakpoint: 1024,
				settings: {
				  slidesToShow: 3,
				  slidesToScroll: 3,
				}
			  },
			  {
				breakpoint: 600,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 2
				}
			  }
			  // You can unslick at a given breakpoint now by adding:
			  // settings: "unslick"
			  // instead of a settings object
			]
		});
	
	if ($(window).width() < 1200) {
	   $(window).scroll(function() {   
		   var scroll = $(window).scrollTop();
		   if (scroll >= 1) {
			   $("#navbar-brand-container").addClass("scrollHeader");
		   } else {
			   $("#navbar-brand-container").removeClass("scrollHeader");
		   }
	   });
	}
	else {
	   $(window).scroll(function() {    
		   var scroll = $(window).scrollTop();
		   if (scroll >= 50) {
			   $("#navbar-brand-container").addClass("scrollHeader");
		   } else {
			   $("#navbar-brand-container").removeClass("scrollHeader");
		   }
	   });
	}
	
	$( ".navbar-toggler" ).on( "click", function() {
		$(this).find('#nav-opener').toggleClass('d-none'); 
		$(this).find('#nav-closer').toggleClass('d-none').toggleClass('d-block'); 
	});
}); 